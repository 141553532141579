import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useConfig } from '../../hooks/useConfig';

export function SEO({ description, lang, meta, keywords, title, image, slug }) {
  const {
    socialUrl,
    mainSEO: {
      title: defaultTitle,
      description: defaultDescription,
      canonicalUrl
    }
  } = useConfig();

  const metaDescription = description || defaultDescription;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s - ${defaultTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:locale`,
          content: `pl_PL`
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:site_name`,
          content: defaultTitle
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ]
        .concat(
          keywords && keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `)
              }
            : []
        )
        .concat(meta)
        .concat(
          image
            ? {
                name: 'og:image',
                content: socialUrl + image
              }
            : []
        )}
    >
      <link rel="canonical" href={canonicalUrl + slug} />
      <script type="text/javascript">
        {`
                (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "bohzaapd80");`}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `pl`,
  meta: [],
  keywords: []
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};
